.container {
  display: flex;
  flex-wrap: wrap;
}

.map {
  flex: 1;
  max-width: 100%;
}

.address {
  flex: 1;
  max-width: 100%;
  margin-top: 20px;
}

/* Responsive adjustments */
@media (min-width: 768px) {
  .container {
    flex-wrap: nowrap;
  }

  .map {
    max-width: 60%;
    margin-right: 20px;
  }

  .address {
    max-width: 55%;
    margin-top: 0;
  }
}

.mr-2 {
  margin-right: 10px !important;
}

.mr-3 {
  margin-right: 20px !important;
}

.mr-4 {
  margin-right: 30px !important;
}

.mr-5 {
  margin-right: 30px !important;
}

.ml-2 {
  margin-right: 10px !important;
}

.mx-2 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.mx-3 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.mx-4 {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.mx-5 {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.ml-2 {
  margin-left: 10px !important;
}

.ml-3 {
  margin-left: 20px !important;
}

.ml-4 {
  margin-left: 30px !important;
}

.ml-5 {
  margin-left: 40px !important;
}
