.comp_data select {
    width: 1000px;
    margin-left: 200px;
    margin-top: 30px;
}

.comp_customer_data {
    display: flex;
    justify-content: space-around;
    margin-top: 75px;
}

.customH5 {
    /* margin-left: -140px; */
    font-weight: 300;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.customButton {
    background-color: rgb(117, 196, 117);
    border: 1px solid rgb(117, 196, 117);
    border-radius: 5px;
    padding: 5px;
    width: 80px;
    color: white;
}

.selectedData select {
    width: 250px;
    margin-left: 200px;
    margin-top: 30px;
}

.selectedData input {
    margin-left: 10px;
    width: 250px;
    margin-top: 20px;
    padding: 8px;
    padding-left: 25px;
    border: 1px solid rgb(127, 125, 125);
    color: rgb(106, 104, 104);
    border-radius: 5px;
}

.selectedData button {
    margin-left: 15px;
    width: 250px;
    padding: 5px;
    border-radius: 5px;

}

.submit_selectedData {
    background-color: rgb(117, 197, 237);
    border: 1px solid rgb(117, 197, 237);
    color: white;
    width: 230px;
    padding: 5px;
    border-radius: 5px;
}

.downLoad_selectedData {
    width: 230px;
    padding: 5px;
    border-radius: 5px;
    background-color: rgb(117, 196, 117);
    border: 1px solid rgb(117, 196, 117);
    color: white;
}


.selectedData h6 {
    background-color: black;
    color: white;
    border-radius: 95%;
    padding: 5px;
    width: 20px;
    text-align: center;
    font-size: 10px;
    margin-left: 600px;

}


.DaterangePicker {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 100px;
    z-index: 999;
    /* width: 350px; */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    color: white;
}

.Stock_Footer_Notes {
    list-style: none;
    /* width: 100%; */
    font-size: 14px;
    padding: 13px;
    text-align: center;
    border: 1px solid rgb(175, 173, 173);
    color: rgb(66, 64, 64);
}

.downLoad_selectedData_StockandSales {
    /* float: right; */
    /* margin-left: 50px; */
    width: 230px;
    padding: 5px;
    border-radius: 5px;
    /* margin-top: -60px;
    margin-right: 290px; */

    background-color: rgb(117, 196, 117);
    border: 1px solid rgb(117, 196, 117);
    color: white;
}