$micro: 320px;
$small: 480px;
$medium: 667px;

.DashboardHeader {
    display: flex;
    justify-content: center;
}

.logo-container {
    @media screen and (max-width: $medium) //480
    {
        display: flex;
        justify-content: center;
    }
}