.MultiColumn {
    color: rgb(255, 255, 255);
    font-weight: 500;
    font-size: 30px;

    max-height: 500px !important;
}




@media(max-width:1000px) {}